import React, { useEffect, useRef, useState } from "react";
import { Calendar } from "../Calendar/Calendar";
import type { Moment } from "moment";
import moment from "moment";
import type { MethodsOfUseForm } from "../../types/types";
import { Controller } from "react-hook-form";
import { getDateTime } from "../../util/util";

// These keys are required by Calendar, but are not required in the DatePickerProps, because the date picker defines them in
// its implementation, and assigns them to Calendar
// To fix this error, I omit them from the DatePickerProps
type HandledByDatePickerProps =
  | "id"
  | "focused"
  | "date"
  | "handleDateChange"
  | "handleFocusChange"
  | "errors";

interface DatePickerProps
  extends Omit<
    React.ComponentProps<typeof Calendar>,
    HandledByDatePickerProps
  > {
  methodsOfUseForm: MethodsOfUseForm;
  defaultValue?: string | null;
  handleOnChange?: () => void;
  normalPlaceholder?: boolean;
  shouldValidate?: boolean;
}

/**
 * Date picker input element. Wraps the <Calendar> element to encapsulate and
 * reduce repetitive boilerplate related to react-hook-form and react state.
 */
export const DatePicker = ({
  label,
  name,
  methodsOfUseForm: { control, register, setValue, errors },
  required,
  defaultValue,
  isOutsideRange,
  handleOnChange,
  normalPlaceholder,
  shouldValidate = false,
  ...rest
}: DatePickerProps) => {
  const [focused, setFocused] = useState(false);

  const [date, setDate] = useState<Moment | null>(
    defaultValue ? moment(defaultValue) : null
  );

  const calendar_ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    register({ name, required: !!required });
    const dateString: string | null = defaultValue
      ? moment(new Date(defaultValue)).toISOString()
      : "";
    setValue(name, dateString || "", { shouldValidate });
  }, [defaultValue, name, register, required, setValue, shouldValidate]);

  const handleDateChange = (
    date: Moment | null,
    onChange: (value: string) => void
  ) => {
    const input_value = (
      calendar_ref.current?.querySelector(
        ".DateInput_input"
      ) as HTMLInputElement | null
    )?.value;
    setDate(date);
    const dateString = date ? moment(date).toISOString() : "";
    // format the input value to MM/DD/YYYY
    const updated_input_value = input_value
      ? moment.utc(getDateTime(input_value, true).date).toISOString()
      : "";
    onChange(dateString || updated_input_value);
    if (handleOnChange) {
      handleOnChange();
    }
  };

  const handleFocusChange = ({ focused }: { focused: boolean | null }) => {
    setFocused(!!focused);
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={
        defaultValue ? moment(new Date(defaultValue)).toISOString() : ""
      }
      render={({ onChange }) => (
        <Calendar
          {...rest}
          label={label}
          date={date}
          focused={focused}
          name={name}
          id={name}
          handleDateChange={(date) => handleDateChange(date, onChange)}
          handleFocusChange={handleFocusChange}
          handleOutsideRange={isOutsideRange}
          normalPlaceholder={normalPlaceholder}
          errors={errors}
          ref={calendar_ref}
          displayFormat="MMM DD, YYYY"
        />
      )}
    />
  );
};
