import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import React, { useCallback, useContext, useState } from "react";
import type { TFunction } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { z } from "zod";
import {
  GoBackButtonSmall,
  InvisibleButton,
  PrimaryButtonMedium,
  PrimaryCancelButton,
  SecondaryButtonMedium,
} from "../../../../../../components/Buttons/Buttons";
import { FilePickerUncontrolled } from "../../../../../../components/FilePicker/FilePickerUncontrolled";
import {
  CSVIcon,
  DownloadIcon,
  InfoIcon,
  LoadingIcon,
} from "../../../../../../components/Icons/Icons";
import { Notifications } from "../../../../../../components/Notifications/NotificationsContext";
import { RadioButton } from "../../../../../../components/RadioButton/RadioButton";
import {
  H3,
  H6Bold,
  RegularTextSmall,
  SmallSectionHeader,
  SmallSectionHeaderRegular,
  SoftHeaderRegular,
  SubheaderContentText,
} from "../../../../../../components/Typography/Typography";
import { endpoints } from "../../../../../../endpoints";
import { RadioButtonContainer } from "../../../../../../layout/FormLayout";
import {
  ContentWrapper,
  FullWidthHorizontalSeparator,
  PageTitle,
  PageWrapper,
} from "../../../../../../layout/portalPageLayout";
import { useFormWrapper, useStoreState } from "../../../../../../util/util";
import { HelpDialog } from "../../../../../SharedPages/OrganizationPage/ProductsList/CreatePimProductPage/CreateFromUploads/HelpDialog";
import { UploadPopups } from "../../../../../SharedPages/OrganizationPage/ProductsList/CreatePimProductPage/CreateFromUploads/UploadPopups";
import { UploadedAssetsList } from "./UploadedAssetsList";

const Container = styled(ContentWrapper)`
  padding: 20px 0;
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
`;

const BackButtonContainer = styled.div`
  margin-bottom: 10px;
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;
`;

const CSVReadyContainer = styled.div`
  margin-top: 20px;
`;

const HelpIcon = styled(InfoIcon)`
  width: 15.2px;
  height: 15.2px;
  flex-grow: 0;
`;

const RadioButtonContainerWithSpacing = styled(RadioButtonContainer)`
  margin-bottom: 24px;
`;

const FileName = styled(SoftHeaderRegular)`
  max-width: 80%;
  color: ${({ theme }) => theme.primaryTextColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const NextStepsList = styled.ol`
  padding-left: 20px;
  margin-bottom: 24px;
`;

const NextStepItem = styled.li`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.secondaryTextColor};
  font-size: 15px;
`;

const UploadCSVContainer = styled.div`
  margin-top: 20px;
  max-width: 600px;
`;

const StyledFilePicker = styled(FilePickerUncontrolled)`
  .file-picker-dropzone {
    width: 100%; // This ensures the dropzone takes full width of its container
    height: 135px;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    padding: 32px 24px;
    border-radius: 4px;
  }
`;

const GuideSection = styled.section`
  position: absolute;
  right: 0;
  top: 40;
`;

const GuideContainer = styled.div`
  width: 165px;
  height: 50px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0;
  font-size: 15px;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FullWidthContainer = styled.div`
  width: 100%;
`;

const LanguageText = styled(RegularTextSmall)`
  text-align: left;
`;

const StyledCSVIcon = styled(CSVIcon)`
  margin-right: 12px;
`;

const DocumentRow = styled.div`
  min-width: 396px;
  min-height: 48px;
  max-width: 53%;
  gap: 16px;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
  border: ${({ theme }) => `solid 1px ${theme.secondaryBorder}`};
  background-color: ${({ theme }) => theme.tertiaryButtonBG};
  div {
    align-items: center;
  }
`;

const FileContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 70%;
`;

const DownloadContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

type ViewState = "select" | "csvReady" | "upload";

const UploadSchema = z.object({
  file: z.instanceof(File).nullable(),
});

// Function to map upload status to user-friendly messages
const getUploadNotificationMessage = (
  status: "in_progress" | "successful" | "failed",
  t: TFunction
): { __html: string } => {
  switch (status) {
    case "in_progress":
      return {
        __html: t(
          "Please wait while we upload and process the CSV. This process may take a few moments, depending on the size of your data. We are ensuring that all information is correctly uploaded and integrated into the system."
        ),
      };
    case "successful":
      return {
        __html: t("Your CSV file was uploaded and processed successfully."),
      };
    case "failed":
      return {
        __html: t(
          "The CSV upload failed. Please try again or check for errors."
        ),
      };
    default:
      return { __html: t("An unknown status was encountered.") };
  }
};

export const AssociateProducts: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [viewState, setViewState] = useState<ViewState>("select");
  const [selectedOption, setSelectedOption] =
    useState<"all" | "previous">("all");
  const [selectedUploads, setSelectedUploads] = useState<string[]>([]);
  const { tenant_id } = useStoreState();
  const [csvContent, setCsvContent] = useState<string | null>(null);
  const [csvFileName, setCsvFileName] = useState("");
  const { notifyError, notifySuccess } = useContext(Notifications);
  const [showGuide, setShowGuide] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [uploadStatus, setUploadStatus] =
    useState<"in_progress" | "successful" | "failed">("in_progress");

  const methodsOfUseForm = useFormWrapper({
    resolver: zodResolver(UploadSchema),
    defaultValues: {
      file: null,
    },
  });

  const { handleSubmit, watch } = methodsOfUseForm;
  const file = watch("file");

  const handleGenerateCSV = async () => {
    try {
      setIsDownloading(true);
      const baseEndpoint =
        endpoints.v2_tenants_tenant_id_pim_assets_uploads_download(tenant_id);
      const scope = selectedOption === "all" ? "all" : "selected";
      const endpoint = `${baseEndpoint}?scope=${scope}`;

      let requestData = {};
      if (scope === "selected") {
        requestData = {
          data: selectedUploads,
        };
      }

      const response = await axios.post(endpoint, requestData, {
        responseType: "blob",
      });

      const content = await response.data.text();
      const fileName =
        scope === "all"
          ? "all_assets_product_association.csv"
          : "selected_assets_product_association.csv";
      setIsDownloading(false);
      setCsvContent(content);
      setCsvFileName(fileName);
      setViewState("csvReady");
    } catch (err) {
      setIsDownloading(false);
      const error = err as any;
      if (error.response && error.response.status === 404) {
        const errorMessage =
          error.response.data?.message ||
          "There are no assets available to download.";
        notifyError(t(errorMessage));
      } else {
        notifyError(t("Error generating CSV"));
      }
    }
  };

  const handleDownload = () => {
    if (csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", csvFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      URL.revokeObjectURL(url);

      setCsvContent(null);
      setCsvFileName("");
      setViewState("select");
    }
  };

  const handleUpload = async (data: { file: File | null }) => {
    if (!data.file) {
      notifyError(t("No file selected"));
      return;
    }

    // Show modal and set status to in_progress
    setShowUploadModal(true);
    setUploadStatus("in_progress");

    const formData = new FormData();
    formData.append("file", data.file);

    try {
      // Add artificial delay
      await new Promise((resolve) => setTimeout(resolve, 3000)); // 3 seconds delay

      const response = await axios.post(
        endpoints.v2_tenants_tenant_id_pim_assets_associate(tenant_id),
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.status === 201) {
        notifySuccess(t("Products associated successfully"));
        setUploadStatus("successful");
        setViewState("select"); // Route back to the select view on success
      }
    } catch (err) {
      const error = err as any;
      if (error.response && error.response.status === 400) {
        // Handle specific 400 errors and display the message
        const errorMessage = error.response.data.message;
        notifyError(t(`Upload failed: ${errorMessage}`));
      } else {
        // Generic error handling for other errors
        notifyError(t("Error uploading file"));
      }
      setUploadStatus("failed");
      setViewState("select"); // Route back to the select view on failure
    } finally {
      setShowUploadModal(false); // Close modal after the process is complete
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const option = event.target.value as "all" | "previous";
    setSelectedOption(option);
    if (option === "all") {
      setSelectedUploads([]);
    }
  };

  const handleBack = () => {
    if (viewState === "upload") {
      setViewState("select");
    } else if (viewState === "csvReady") {
      setViewState("select");
    } else {
      history.goBack();
    }
  };

  const handleSelectUploads = useCallback((selectedAssets: string[]) => {
    setSelectedUploads(selectedAssets);
  }, []);

  const renderSelectView = () => (
    <FullWidthContainer>
      <H3>{t("Bulk Associate")}</H3>
      <SubheaderContentText>
        {t("Please wait while we prepare your data for download. This process")}
        <br />
        {t(
          "ensures that all information is accurate and up-to-date. The download"
        )}
        <br />
        {t("should be ready shortly. Thank you for your patience!")}
      </SubheaderContentText>
      <SmallSectionHeader style={{ margin: "24px 0 16px" }}>
        {t("Download CSV")}
      </SmallSectionHeader>
      <RadioButtonContainerWithSpacing>
        <RadioButton
          name="assetSelection"
          value="all"
          optionTitle={t("Select all assets")}
          handleChange={handleOptionChange}
          checked={selectedOption === "all"}
        />
        <RadioButton
          name="assetSelection"
          value="previous"
          optionTitle={t("Select from previous uploads")}
          handleChange={handleOptionChange}
          checked={selectedOption === "previous"}
        />
      </RadioButtonContainerWithSpacing>
      {selectedOption === "previous" && (
        <FullWidthContainer>
          <UploadedAssetsList
            setStage={() => {}}
            onSelect={handleSelectUploads}
            isCheckBoxRequired={true}
          />
        </FullWidthContainer>
      )}
      <ButtonContainer>
        <PrimaryCancelButton onClick={handleBack}>
          {t("Cancel")}
        </PrimaryCancelButton>
        <SecondaryButtonMedium onClick={() => setViewState("upload")}>
          {t("Upload CSV")}
        </SecondaryButtonMedium>
        <PrimaryButtonMedium
          onClick={handleGenerateCSV}
          disabled={
            (selectedOption === "previous" && selectedUploads.length === 0) ||
            isDownloading
          }
        >
          {isDownloading && (
            <span style={{ paddingRight: "8px" }}>
              <LoadingIcon width={14} height={14} />
            </span>
          )}
          {isDownloading ? t("Downloading") : t("Download")}
        </PrimaryButtonMedium>
      </ButtonContainer>
    </FullWidthContainer>
  );

  const renderCSVReadyView = () => (
    <CSVReadyContainer>
      <H3>{t("Assets Data Ready!")}</H3>
      <SubheaderContentText style={{ marginBottom: "8px" }}>
        {t(
          "Your assets file is ready for download. You can now download the file and proceed"
        )}
        <br />
        {t("to upload")}
      </SubheaderContentText>
      <DocumentRow>
        <FileContainer>
          <StyledCSVIcon />
          <FileName>{csvFileName}</FileName>
        </FileContainer>
        <DownloadContainer>
          <LanguageText>{t("English (EN)")}</LanguageText>
          <InvisibleButton type="button" onClick={handleDownload}>
            <DownloadIcon width={16} height={16} />
          </InvisibleButton>
        </DownloadContainer>
      </DocumentRow>
      <H3>{t("Next Steps")}</H3>
      <NextStepsList>
        <NextStepItem>
          {t("Click the download icon to download the CSV file .")}
        </NextStepItem>
        <NextStepItem>
          {t("List the product id's against the assets file name.")}
        </NextStepItem>
        <NextStepItem>
          {t(
            "Once the CSV is listed with all the product id's against the assets, return to"
          )}
          <br />
          {t(
            "the associate products page and choose to upload the CSV and finalize"
          )}
          <br />
          {t("the process.")}
        </NextStepItem>
      </NextStepsList>
      <PrimaryButtonMedium onClick={handleDownload}>
        {t("Done")}
      </PrimaryButtonMedium>
    </CSVReadyContainer>
  );

  const renderUploadView = () => (
    <UploadCSVContainer>
      <H3>{t("Upload CSV")}</H3>
      <SubheaderContentText>
        {t("Upload the assets CSV to associate the assets to products")}
      </SubheaderContentText>
      <form onSubmit={handleSubmit(handleUpload)}>
        <StyledFilePicker
          methodsOfUseForm={methodsOfUseForm}
          name="file"
          accept={[".csv"]}
          placeHolderText={t("Drag and drop CSV file here or")}
          description={t("Only CSV files are accepted")}
          required={true}
        />
        <ButtonContainer>
          <PrimaryCancelButton onClick={handleBack}>
            {t("Cancel")}
          </PrimaryCancelButton>
          <PrimaryButtonMedium type="submit" disabled={!file}>
            {t("Continue")}
          </PrimaryButtonMedium>
        </ButtonContainer>
      </form>
    </UploadCSVContainer>
  );

  const guidelineOverview = (t: TFunction) => [
    t(
      "Each CSV will have assets file name, type, category and language. You can download all assets by selecting all assets or choose to select assets from previous uploads."
    ),
  ];

  const csvGuidelines = (t: TFunction) => [
    t("Do not modify the header row."),
    t("Do not change the file name of the CSVs."),
    t("The date columns should be in YYYY-MM-DD."),

    t(
      "Enter the alpha-2 code of the respective country under country column. Example:- US (United States of America), BR (Brazil) etc."
    ),
    t(
      "Input the Product ID to associate the respective product to the asset under the Product ID header"
    ),
    t(
      "If there are more that one products, input product id separated by semicolon (;)."
    ),
    t(
      "Once the CSV is updated with the product id, upload the CSV to associate products to the assets."
    ),
  ];

  return (
    <PageWrapper>
      <BackButtonContainer>
        <InvisibleButton onClick={handleBack}>
          <GoBackButtonSmall text={t("Back")} />
        </InvisibleButton>
      </BackButtonContainer>
      <TitleContainer>
        <PageTitle>{t("Bulk Associate")}</PageTitle>
      </TitleContainer>
      <FullWidthHorizontalSeparator />
      <Container>
        <PageContent>
          {viewState === "select" && renderSelectView()}
          {viewState === "csvReady" && renderCSVReadyView()}
          {viewState === "upload" && renderUploadView()}
          <GuideSection>
            <GuideContainer>
              <HelpDialog
                show={showGuide}
                setShow={setShowGuide}
                overview={guidelineOverview}
                guidelines={csvGuidelines}
                guidelineHeader={t("Guidelines for Updating Your CSV Files")}
              />
              <SmallSectionHeaderRegular style={{ margin: 0 }}>
                {t("Need help getting started?")}
              </SmallSectionHeaderRegular>
              <div
                onClick={() => setShowGuide(true)}
                style={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <HelpIcon />
                <H6Bold>{t("View Guide")}</H6Bold>
              </div>
            </GuideContainer>
          </GuideSection>
        </PageContent>
        <UploadPopups
          showDialog={showUploadModal}
          onComplete={() => setShowUploadModal(false)}
          setShowDialog={setShowUploadModal}
          message={
            <span
              dangerouslySetInnerHTML={getUploadNotificationMessage(
                uploadStatus,
                t
              )}
            />
          }
          status={uploadStatus}
          uploadId={""}
        />
      </Container>
    </PageWrapper>
  );
};
