import type { AxiosError } from "axios";
import Axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import { useAuthContext } from "../../../../../components/Auth";
import { PrimaryButtonWithPlusIcon } from "../../../../../components/Buttons/Buttons";
import {
  DeleteIcon,
  EditIcon,
  NonVisibleIcon,
  VisibleIcon,
} from "../../../../../components/Icons/Icons";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../../components/Table/Table";
import { H3, SmallText } from "../../../../../components/Typography/Typography";
import { endpoints } from "../../../../../endpoints";
import type { AnalyticsProp, DataMutate } from "../../../../../types/types";
import type {
  AssetsExternalLinks,
  PIMProduct,
} from "../../../../../types/types.PIM";
import {
  formatDate,
  TablePlaceholder,
  useStoreState,
} from "../../../../../util/util";
import { AssetLink } from "../util";
import { AddandEditLink } from "./AddandEditLink";
import { DeleteExternalLink } from "./DeleteExternalLink";

const TableContainer = styled.div`
  margin-bottom: 24px;
  & div[class*="TableWrapper"] {
    overflow: visible;
    td {
      overflow: visible;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
`;

const BtnWrapper = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

type TableProduct = {
  name: string;
  url: string;
};

export const ExternalLinksTable = ({
  external_links,
  fetchProductData,
  product,
}: {
  external_links: AssetsExternalLinks[];
  fetchProductData: DataMutate<PIMProduct>;
  product: PIMProduct;
}) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { notifySuccess, notifyError } = useContext(Notifications);

  const { tenant_id } = useStoreState();
  const { hasPermission } = useAuthContext();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showSlideOut, setSlideOut] =
    useState<"add" | "edit" | "delete" | false>(false);

  const [tableData, setTableData] = useState<TableProduct[]>([]);
  const [editRowData, setEditRowData] = useState<any>();
  const [deleteRowData, setDeleteRowData] = useState<any>();

  const closeSlideOut = () => setSlideOut(false);
  const closeDeleteModal = () => setShowDeleteModal(false);

  const handleChangeVisibility = useCallback(
    (original) => {
      (async (original: AssetsExternalLinks) => {
        try {
          const linkId = original?.id;
          const isVisible = original?.is_visible;
          const patchBaseUrl =
            endpoints.v2_storefronts_id_pim_assets_external_links_id(
              tenant_id,
              linkId
            );

          await Axios.patch(patchBaseUrl, { is_visible: !isVisible });
          await fetchProductData();
          notifySuccess(t("External link successfully Edited"));
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage
              ? t("{{axios_error_message}}", {
                  axios_error_message: errorMessage,
                })
              : t("Could not edit an asset. Something went wrong."),
            {
              error,
            }
          );
        }
      })(original);
    },
    [fetchProductData, notifyError, notifySuccess, t, tenant_id]
  );

  const tableColumns = React.useMemo(
    () => [
      {
        header: t("Name"),
        accessorKey: "name",
        cell: ({
          getValue,
          row: {
            original: { url },
          },
        }: {
          getValue: () => string;
          row: {
            original: {
              url: string;
            };
          };
        }) => {
          const analytics: AnalyticsProp = {
            analyticsName: getValue(),
            objectId: product.product_number ?? product.id,
            eventType: "product_link",
            link: url,
          };
          return (
            <AssetLink
              analytics={analytics}
              url={url}
              name={getValue()}
              is_visible={true}
            />
          );
        },
      },
      {
        header: t("Last Modified"),
        accessorKey: "modified_at",
      },
      {
        header: t("Last Modified By"),
        accessorKey: "modified_by",
      },
      {
        header: product.status !== "archived" ? t("Actions") : "",
        accessorKey: "is_visible",
        enableSorting: false,
        cell: ({
          row: { original },
          getValue,
        }: {
          row: { original: AssetsExternalLinks };
          getValue: () => boolean;
        }) =>
          product.status !== "archived" &&
          product.is_editable && (
            <IconWrapper>
              {original.is_editable && hasPermission("modify_assets") && (
                <BtnWrapper onClick={() => handleEdit(original)}>
                  <EditIcon width={20} height={20} />
                </BtnWrapper>
              )}
              {getValue() && hasPermission("modify_assets") && (
                <BtnWrapper onClick={() => handleChangeVisibility(original)}>
                  <VisibleIcon
                    fill={theme.successIconColor}
                    width={20}
                    height={20}
                  />
                </BtnWrapper>
              )}
              {!getValue() && hasPermission("modify_assets") && (
                <BtnWrapper onClick={() => handleChangeVisibility(original)}>
                  <NonVisibleIcon
                    fill={theme.errorColor}
                    width={20}
                    height={20}
                  />
                </BtnWrapper>
              )}
              {original.is_editable && hasPermission("delete_assets") && (
                <BtnWrapper onClick={() => handleDelete(original)}>
                  <DeleteIcon fill={theme.errorColor} />
                </BtnWrapper>
              )}
            </IconWrapper>
          ),
      },
    ],
    [
      t,
      product.status,
      product.product_number,
      product.id,
      product.is_editable,
      hasPermission,
      theme.successIconColor,
      theme.errorColor,
      handleChangeVisibility,
    ]
  );

  useEffect(() => {
    const handleProductsData = (external_links: AssetsExternalLinks[]) => {
      setTableData(
        external_links.reduce<
          (TableProduct &
            {
              [Prop in keyof AssetsExternalLinks]+?: AssetsExternalLinks[Prop];
            })[]
        >(
          (
            acc,
            { id, url, name, is_visible, modified_at, modified_by, is_editable }
          ) => {
            acc.push({
              id,
              url,
              name: name ?? "--",
              modified_at: formatDate(modified_at) ?? "--",
              modified_by: modified_by ?? "--",
              is_visible,
              is_editable,
            });
            return acc;
          },
          []
        )
      );
    };

    handleProductsData(external_links);
  }, [external_links]);

  const handleDelete = (original: AssetsExternalLinks) => {
    setShowDeleteModal(true);
    setDeleteRowData(original);
  };

  const handleEdit = (original: AssetsExternalLinks) => {
    setSlideOut("edit");
    setEditRowData(original);
  };
  return (
    <>
      <H3 style={{ marginBottom: "4px" }}>{t("External Links")}</H3>
      <SmallText style={{ marginBottom: "16px" }}>
        {t("Manage External Links and control their accessibility")}
      </SmallText>

      <TableContainer>
        <Table
          columns={tableColumns}
          data={tableData}
          isLoading={false}
          // this will never render if the product itself errro
          error={undefined}
          lastChildleftAlign
          Placeholder={<TablePlaceholder />}
        />
      </TableContainer>

      {showSlideOut === "add" && (
        <SlideOut closeFlyout={closeSlideOut} show={!!showSlideOut}>
          <AddandEditLink
            formType="add"
            refreshAssetsList={fetchProductData}
            onSuccess={closeSlideOut}
          />
        </SlideOut>
      )}

      {showSlideOut === "edit" && (
        <SlideOut closeFlyout={closeSlideOut} show={!!showSlideOut}>
          <AddandEditLink
            formType="edit"
            refreshAssetsList={fetchProductData}
            editData={editRowData}
            onSuccess={closeSlideOut}
          />
        </SlideOut>
      )}

      {showDeleteModal && (
        <DeleteExternalLink
          onSuccess={closeDeleteModal}
          show={showDeleteModal}
          onClose={closeDeleteModal}
          refreshAssetsList={fetchProductData}
          deleteData={deleteRowData}
        />
      )}
      {hasPermission("modify_assets") &&
        product.status !== "archived" &&
        product.is_editable && (
          <PrimaryButtonWithPlusIcon
            style={{ width: "fit-content" }}
            onClick={() => setSlideOut("add")}
          >
            {t("Add")}
          </PrimaryButtonWithPlusIcon>
        )}
    </>
  );
};
