import { zodResolver } from "@hookform/resolvers/zod";
import type { AxiosError } from "axios";
import axios from "axios";
import type { ChangeEvent } from "react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import useSWR from "swr";
import {
  ButtonWithConfirmDialog,
  GoBackButton,
  DeleteButtonWithText,
  PrimaryButtonWithPlusIcon,
  DeleteButton,
} from "../../../../../components/Buttons/Buttons";
import { DelayedSpinner } from "../../../../../components/DelayedSpinner/DelayedSpinner";
import { EditableTitle } from "../../../../../components/EditableTitle/EditableTitle";
import { ErrorPlaceholder } from "../../../../../components/Error";
import { Notifications } from "../../../../../components/Notifications/NotificationsContext";
import { SlideOut } from "../../../../../components/SlideOut/SlideOut";
import { Table } from "../../../../../components/Table/Table";
import { ContentWrapper } from "../../../../../layout/publicPageLayout";
import type { TranslationsSchema, UUID } from "../../../../../types/types";
import type {
  AttributeGroupSummary,
  AttributeTemplateSchema,
} from "../../../../../types/types.PIM";
import { useRoutePath } from "../../../../../util/Routing";
import {
  removeUnderscore,
  rowHover,
  toTitleCase,
  useFormWrapper,
  useHasMultipleLanguages,
  useStoreState,
} from "../../../../../util/util";
import { AttributesNav } from "../components/AttributesNav";
import { AddAttributeToExistingGroup } from "./AddAttributeToExistingGroup";
import { z } from "zod";
import { TemplatesNav } from "../SellerAdminTemplates/TemplatesNav";
import { NumberParam, StringParam, useQueryParams } from "use-query-params";
import { PageWrapper } from "../../../../../layout/portalPageLayout";
import { zodRequiredString } from "../../../../../util/zod.util";
import { Flex } from "../../../../../layout/FormLayout";
import { SystemIconWrapper } from "../components/PIM.components.util";
import {
  InfoIcon,
  LoadingIcon,
  SystemDefaultIcon,
} from "../../../../../components/Icons/Icons";
import {
  H3,
  SmallText,
  SoftHeader2,
  SoftHeaderMedium,
  SoftHeaderMediumDarkText,
} from "../../../../../components/Typography/Typography";
import {
  CheckBoxNoMargin,
  HeadingSection,
  RestrictedHeader,
  TableHeaderSection,
} from "../SellerAdminPIMAttributesCollections/SellerAdminAttributesCollectionDetails/SellerAdminAttributesCollectionDetails";
import { useAuthContext } from "../../../../../components/Auth";
import { useTheme } from "styled-components/macro";
import { endpoints } from "../../../../../endpoints";
import Axios from "axios";
import { GroupTranslations } from "./GroupTranslations";
import type { ColumnDef } from "@tanstack/react-table";

const localstrings = (t: (s: string) => string) => ({
  tooltipAddAttributeSystemDefaultError: t(
    "You cannot add attributes to a system default group"
  ),
  tooltipAddAttributeDefaultTemplateError: t(
    "You cannot add attributes to default template"
  ),
  tooltipDeleteAttributeSystemDefaultError: t(
    "You cannot remove attributes from a system default group"
  ),
  tooltipDeleteAttributeDefaultTemplateError: t(
    "You cannot remove attributes from a default Template"
  ),
  tooltipDeleteGroupSystemDefaultError: t(
    "You cannot remove a system default group"
  ),
  tooltipDeleteGroupDefaultTemplateError: t(
    "You cannot remove group from default template"
  ),
  tooltipDeleteTemplateInUseError: t(
    "Products are using the template, please duplicate the template for any archival changes"
  ),
  tooltipEditTitleSystemDefaultError: t(
    "You cannot edit a system default group"
  ),
  tooltipEditTitleDefaultTemplateError: t(
    "You cannot edit default template title"
  ),
  tooltipEditTitlePermissionError: t(
    "You don't have permission to edit this title"
  ),
});

const isLastAttribute = (attributes: any[]) => attributes.length === 1;

/**
 * This page component is used in both the template groups section and the
 * regular groups section. One day the behavior might diverge enough that we
 * need to split this.
 *
 * differences in behavior:
 * 1. API calls in "template mode" modify the group inside the template
 * 2. when a group is modified from inside a template, the "modify_templates"
 *    permission applies. When modifying the group itself its "modify_groups"
 */
export function AttributeGroupDetail() {
  const { tenant_id } = useStoreState();
  const hasMultipleLanguages = useHasMultipleLanguages();
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<
    {
      uuid: UUID;
      attribute_name: string;
      input_type: string;
      attribute_display_name: string;
      restricted: boolean;
    }[]
  >([]);
  const { group_id, template_id } =
    useParams<{ group_id: string; template_id?: string }>();
  const { adminPath } = useRoutePath();
  const [query] = useQueryParams({
    q: StringParam,
    offset: NumberParam,
    perPage: NumberParam,
  });

  const [showAddAttributeToGroupForm, setShowAddAttributeToGroupForm] =
    useState(false);

  const editTitleMethodsOfUseForm = useFormWrapper({
    resolver: zodResolver(
      z.object({
        title: zodRequiredString(t),
        type: z.enum(["name"]),
      })
    ),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const editDisplayNameMethodsOfUseForm = useFormWrapper({
    resolver: zodResolver(
      z.object({
        title: zodRequiredString(t),
        type: z.enum(["display_name"]),
      })
    ),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const { notifyError, notifySuccess } = useContext(Notifications);

  const history = useHistory();
  const location = useLocation();

  const titleRef = useRef<{ setEditMode: (editMode: boolean) => void }>(null);

  const displayNameRef =
    useRef<{ setEditMode: (editMode: boolean) => void }>(null);

  const isInTemplateSection = !!template_id;
  const { hasPermission } = useAuthContext();
  const theme = useTheme();
  const {
    data: attributeGroupsResponse,
    error: attributesGroupsError,
    mutate: mutateGroup,
  } = useSWR<AttributeGroupSummary & { translations: TranslationsSchema[] }>(
    [
      `/v2/tenants/${tenant_id}/pim/groups/${group_id}/attributes`,
      useMemo(() => ({ params: { include_translations: true } }), []),
    ],
    {
      onSuccess: (data) =>
        editTitleMethodsOfUseForm.setValue("title", data.name),
    }
  );

  const { data: template, mutate: mutateTemplate } = useSWR<
    AttributeTemplateSchema,
    AxiosError
  >(
    template_id ? `/v2/tenants/${tenant_id}/pim/templates/${template_id}` : null
  );

  const canModifyGroup =
    (isInTemplateSection && hasPermission("modify_templates")) ||
    (!isInTemplateSection && hasPermission("modify_groups"));

  const canDeleteGroup = isInTemplateSection
    ? hasPermission("delete_templates")
    : hasPermission("delete_groups");

  const groupID = attributeGroupsResponse?.id;

  const isDefaultTemplate = template?.template_name === "Default Template";

  const isLoadingAttributes =
    !attributeGroupsResponse && !attributesGroupsError;

  const isTemplateInUse = template?.is_frozen ?? false;

  const deleteAttributeDataTip = useCallback(() => {
    if (isDefaultTemplate) {
      return localstrings(t).tooltipDeleteAttributeDefaultTemplateError;
    } else if (isTemplateInUse) {
      return localstrings(t).tooltipDeleteTemplateInUseError;
    } else if (attributeGroupsResponse?.is_system) {
      return localstrings(t).tooltipDeleteAttributeSystemDefaultError;
    } else {
      return "";
    }
  }, [
    isDefaultTemplate,
    attributeGroupsResponse?.is_system,
    isTemplateInUse,
    t,
  ]);

  const deleteGroupDataTip = useCallback(() => {
    if (isDefaultTemplate) {
      return localstrings(t).tooltipDeleteGroupDefaultTemplateError;
    } else if (isTemplateInUse) {
      return localstrings(t).tooltipDeleteTemplateInUseError;
    } else if (attributeGroupsResponse?.is_system) {
      return localstrings(t).tooltipDeleteGroupSystemDefaultError;
    } else {
      return "";
    }
  }, [
    attributeGroupsResponse?.is_system,
    isDefaultTemplate,
    isTemplateInUse,
    t,
  ]);

  const handleGroupDelete = useCallback(async () => {
    try {
      await axios.delete(`/v2/tenants/${tenant_id}/pim/groups/${groupID}`);
      notifySuccess(t("Group removed successfully"));
      history.push(`${adminPath}/pim/attributes/groups`);
    } catch (error) {
      const errorMessage = (error as AxiosError)?.response?.data?.message;
      notifyError(
        errorMessage
          ? errorMessage
          : t("There was an error deleting the group"),
        {
          error,
        }
      );
    }
  }, [groupID, tenant_id, notifySuccess, history, notifyError, t, adminPath]);

  const handleAttributesReorder = async (tableData: Array<any>) => {
    const reorderList = tableData.map((row) => row.uuid);

    try {
      await Axios.post(
        `/v2/tenants/${tenant_id}/pim/groups/${group_id}/priority`,
        {
          items: reorderList,
        }
      );
      mutateTemplate();
      notifySuccess(t("Your changes have been saved successfully"));
    } catch (error) {
      notifyError(t("Something went wrong, please try again"), { error });
      attributeGroupsResponse &&
        setTableData(
          attributeGroupsResponse.attributes.map((attr) => ({
            uuid: attr.id,
            attribute_name: attr.name,
            input_type: attr.input_type,
            attribute_display_name: attr.display_name
              ? t([attr.display_name])
              : "",
            restricted: attr.is_restricted,
          }))
        );
    }
  };

  useEffect(() => {
    const handleGroupData = (data: AttributeGroupSummary) => {
      setTableData(
        data.attributes.map((attr) => ({
          uuid: attr.id,
          attribute_name: attr.name,
          input_type: attr.input_type,
          attribute_display_name: attr.display_name
            ? t([attr.display_name])
            : "",
          restricted: attr.is_restricted,
        }))
      );
    };

    if (attributeGroupsResponse) {
      handleGroupData(attributeGroupsResponse);
    }
  }, [attributeGroupsResponse, setTableData, t]);

  const tableColumns = React.useMemo<
    ColumnDef<{
      uuid: UUID;
      attribute_name: string;
      input_type: string;
      attribute_display_name: string;
      restricted: boolean;
    }>[]
  >(() => {
    const handleDeleteAttributeFromGroup = async (uuid: UUID) => {
      try {
        const requestBody = { attribute_ids: [uuid] };
        await axios.delete(
          `/v2/tenants/${tenant_id}/pim/groups/${groupID}/attributes`,
          { data: { ...requestBody } }
        );
        notifySuccess(t("attribute removed successfully"));
        if (isLastAttribute(attributeGroupsResponse?.attributes ?? [])) {
          await handleGroupDelete();
        } else {
          mutateGroup();
        }
      } catch (error) {
        const errorMessage = (error as AxiosError)?.response?.data?.message;
        notifyError(
          errorMessage ? errorMessage : t("Failed to remove attribute"),
          {
            error,
          }
        );
      }
    };

    return isInTemplateSection
      ? [
          {
            header: t("Attribute Name"),
            accessorKey: "attribute_name",
          },
          {
            // I feel like this wrapping in styled divs shoudln't be needed but this
            // is a way to make it look like the design.
            header: () => (
              <div style={{ textAlign: "left" }}>{t("Attribute Type")}</div>
            ),
            accessorKey: "input_type",
            cell: (cell) => (
              <div style={{ textAlign: "left" }}>
                {toTitleCase(removeUnderscore(cell.getValue() as string))}
              </div>
            ),
          },
          {
            header: t("Attribute Display Name"),
            accessorKey: "attribute_display_name",
          },
          {
            header: () => {
              let isLoading = false;
              const getIsLoading = () => isLoading;

              const handleCheckBoxChange = async (
                e: ChangeEvent<HTMLInputElement>
              ) => {
                const isChecked = e.target.checked;
                const patchRequestBody = isChecked
                  ? { attributes_to_restrict: "all" }
                  : { attributes_to_unrestrict: "all" };
                isLoading = true;
                try {
                  await axios.patch<
                    { attribute_ids: "all" | string[] },
                    AttributeTemplateSchema
                  >(
                    endpoints.v2_tenants_id_or_slug_pim_templates_id_groups_id_attributes_restricted(
                      tenant_id,
                      template!.template_id,
                      attributeGroupsResponse!.id
                    ),
                    patchRequestBody
                  );
                  await mutateTemplate();
                  await mutateGroup();
                } catch (error) {
                  const errorMessage = (error as AxiosError)?.response?.data
                    ?.message;
                  notifyError(
                    errorMessage
                      ? errorMessage
                      : t(
                          "Could not update restriction. Please try again later."
                        ),
                    {
                      error,
                    }
                  );
                } finally {
                  isLoading = false;
                }
              };
              return (
                <RestrictedHeader>
                  <>
                    {getIsLoading() ? (
                      <LoadingIcon width={16} height={18} />
                    ) : (
                      <CheckBoxNoMargin
                        name="restricted-all"
                        checked={
                          attributeGroupsResponse?.is_restricted ?? false
                        }
                        onChange={handleCheckBoxChange}
                      />
                    )}
                  </>
                  {t("Restricted")}
                  <span
                    data-tip={t(
                      `Attributes marked "Restricted" are only visible to your organization's users. These are not shared with any customers, distributors or other digital connections`
                    )}
                    data-for="restricted-header-tip"
                  >
                    <InfoIcon
                      fill={theme.secondaryIconColor}
                      width={14}
                      height={14}
                    />
                  </span>
                  <ReactTooltip
                    id="restricted-header-tip"
                    delayHide={500}
                    clickable
                    effect="solid"
                  />
                </RestrictedHeader>
              );
            },
            accessorKey: "restricted",
            Cell: ({
              value,
              row: {
                original: { uuid },
              },
            }: {
              value: boolean;
              row: { original: { uuid: string } };
            }) => {
              const [isLoading, setIsLoading] = useState(false);
              const handleCheckBoxChange = async (
                e: ChangeEvent<HTMLInputElement>
              ) => {
                const isChecked = e.target.checked;
                const patchRequestBody = isChecked
                  ? { attributes_to_restrict: [uuid] }
                  : { attributes_to_unrestrict: [uuid] };
                setIsLoading(true);
                try {
                  await axios.patch<
                    {
                      attributes_to_restrict?: string[];
                      attributes_to_unrestrict?: string[];
                    },
                    AttributeTemplateSchema
                  >(
                    endpoints.v2_tenants_id_or_slug_pim_templates_id_groups_id_attributes_restricted(
                      tenant_id,
                      template!.template_id,
                      attributeGroupsResponse!.id
                    ),
                    patchRequestBody
                  );
                  await mutateTemplate();
                  await mutateGroup();
                } catch (error) {
                  const errorMessage = (error as AxiosError)?.response?.data
                    ?.message;
                  notifyError(
                    errorMessage
                      ? errorMessage
                      : t(
                          "Could not update restriction. Please try again later."
                        ),
                    {
                      error,
                    }
                  );
                } finally {
                  setIsLoading(false);
                }
              };
              return (
                <>
                  {isLoading ? (
                    <span style={{ margin: "3px 15px 3px 0" }}>
                      <LoadingIcon width={16} height={18} />
                    </span>
                  ) : (
                    <CheckBoxNoMargin
                      name={`restricted-${uuid}`}
                      checked={value}
                      onChange={handleCheckBoxChange}
                    />
                  )}
                </>
              );
            },
          },
          {
            // The empty header and accessor are needed so it doesn't crash.
            header: "",
            accessorKey: " ",
            Cell: ({
              row: { original },
            }: {
              row: { original: { uuid: string } };
            }) =>
              canDeleteGroup ? (
                <ButtonWithConfirmDialog
                  Button={(props) => (
                    <>
                      <DeleteButton
                        {...props}
                        datafor="attribute-delete-button"
                        datatip={deleteAttributeDataTip()}
                      />
                      <ReactTooltip id="attribute-delete-button" />
                    </>
                  )}
                  testid={"delete-individiual-attribute-from-group"}
                  disabled={
                    attributeGroupsResponse?.is_system ||
                    isDefaultTemplate ||
                    template?.is_frozen ||
                    isTemplateInUse
                  }
                  handleConfirm={() =>
                    handleDeleteAttributeFromGroup(original.uuid)
                  }
                  confirmMessage={
                    isLastAttribute(attributeGroupsResponse!.attributes)
                      ? t(
                          "Are you sure you want to remove this attribute? This group will be removed."
                        )
                      : t("Are you sure you want to remove this attribute?")
                  }
                />
              ) : null,
          },
        ]
      : [
          {
            header: t("Attribute Name"),
            accessorKey: "attribute_name",
          },
          {
            // I feel like this wrapping in styled divs shoudln't be needed but this
            // is a way to make it look like the design.
            header: () => (
              <div style={{ textAlign: "left" }}>{t("Attribute Type")}</div>
            ),
            accessorKey: "input_type",
            Cell: ({ value }: { value: string }) => (
              <div style={{ textAlign: "left" }}>
                {toTitleCase(removeUnderscore(value))}
              </div>
            ),
          },
          {
            header: t("Attribute Display Name"),
            accessorKey: "attribute_display_name",
          },
          {
            // The empty header and accessor are needed so it doesn't crash.
            header: "",
            accessorKey: " ",
            Cell: ({
              row: { original },
            }: {
              row: { original: { uuid: string } };
            }) =>
              canDeleteGroup ? (
                <ButtonWithConfirmDialog
                  Button={(props) => (
                    <>
                      <DeleteButton
                        {...props}
                        datafor="attribute-delete-button"
                        datatip={deleteAttributeDataTip()}
                      />
                      <ReactTooltip id="attribute-delete-button" />
                    </>
                  )}
                  testid={"delete-individiual-attribute-from-group"}
                  disabled={
                    attributeGroupsResponse?.is_system ||
                    isDefaultTemplate ||
                    template?.is_frozen ||
                    isTemplateInUse
                  }
                  handleConfirm={() =>
                    handleDeleteAttributeFromGroup(original.uuid)
                  }
                  confirmMessage={
                    isLastAttribute(attributeGroupsResponse!.attributes)
                      ? t(
                          "Are you sure you want to remove this attribute? This group will be removed."
                        )
                      : t("Are you sure you want to remove this attribute?")
                  }
                />
              ) : null,
          },
        ];
  }, [
    isInTemplateSection,
    t,
    tenant_id,
    groupID,
    notifySuccess,
    attributeGroupsResponse,
    handleGroupDelete,
    mutateGroup,
    notifyError,
    template,
    mutateTemplate,
    canDeleteGroup,
    isDefaultTemplate,
    isTemplateInUse,
    deleteAttributeDataTip,
    theme,
  ]);

  if (isLoadingAttributes) {
    return <DelayedSpinner />;
  }

  if (attributesGroupsError) {
    return (
      <ErrorPlaceholder message={"There was an error loading the group"} />
    );
  }

  if (attributeGroupsResponse) {
    const handleTitleEditConfirm = async ({
      title,
      type,
    }: {
      title: string;
      type: "name" | "display_name";
    }) => {
      const canSubmit = () =>
        (type === "name" && title.trim() !== attributeGroupsResponse.name) ||
        (type === "display_name" &&
          title.trim() !== attributeGroupsResponse.display_name);

      if (canSubmit()) {
        try {
          await axios.patch(
            `/v2/tenants/${tenant_id}/pim/groups/${groupID}/summary`,
            type === "name"
              ? {
                  name: title.trim(),
                  description: attributeGroupsResponse.description ?? "",
                }
              : {
                  display_name: title.trim(),
                  description: attributeGroupsResponse.description ?? "",
                }
          );
          // success notification intentionally left out because this is "inline"
          if (type === "name") {
            titleRef.current?.setEditMode(false);
          } else {
            displayNameRef.current?.setEditMode(false);
          }
        } catch (error) {
          const errorMessage = (error as AxiosError)?.response?.data?.message;
          notifyError(
            errorMessage ? errorMessage : t("Error editing group title"),
            {
              error,
            }
          );
        }
        mutateGroup();
      }
    };

    const params = new URLSearchParams();
    if (query.q) {
      params.append("q", query.q);
    }
    params.append("offset", String(query?.offset ?? 0));
    params.append("perPage", String(query?.perPage ?? 10));

    const handleGroupDelete = async () => {
      try {
        await axios.delete(`/v2/tenants/${tenant_id}/pim/groups/${groupID}`);
        notifySuccess(t("Group removed successfully"));
        isInTemplateSection
          ? history.push(`${adminPath}/pim/templates/${template_id}/groups`)
          : history.push(`${adminPath}/pim/attributes/groups`);
      } catch (error) {
        notifyError(t("There was an error deleting the group"), { error });
        console.error(error);
      }
    };

    return (
      <PageWrapper>
        {isInTemplateSection ? (
          <TemplatesNav
            pageTitle={template?.template_name ?? ""}
            tabIndex={0}
            templateID={template_id!}
          />
        ) : (
          <AttributesNav tabName={"Groups"} params={params} />
        )}
        <div style={{ width: "fit-content" }}>
          <Link
            to={
              location.search.includes("from=dashboard")
                ? `${adminPath}/dashboard`
                : isInTemplateSection
                ? `${adminPath}/pim/templates/${template_id}/groups?${params}`
                : `${adminPath}/pim/attributes/groups?${params}`
            }
          >
            <GoBackButton text="Groups"></GoBackButton>
          </Link>
        </div>
        <TableHeaderSection>
          <HeadingSection>
            {isInTemplateSection ? (
              <>
                <H3 style={{ margin: 0 }}>{attributeGroupsResponse.name}</H3>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <SoftHeaderMedium>{t("Display name:")}</SoftHeaderMedium>
                  <SoftHeaderMediumDarkText>
                    {attributeGroupsResponse.display_name
                      ? t([attributeGroupsResponse.display_name])
                      : attributeGroupsResponse.name}
                  </SoftHeaderMediumDarkText>
                </div>
              </>
            ) : (
              <>
                <form
                  id="edit-title-form"
                  noValidate
                  onSubmit={editTitleMethodsOfUseForm.handleSubmit(
                    handleTitleEditConfirm
                  )}
                >
                  <EditableTitle
                    title={attributeGroupsResponse.name}
                    formId="edit-title-form"
                    name="title"
                    methodsOfUseForm={editTitleMethodsOfUseForm}
                    disabled={
                      attributeGroupsResponse.is_system ||
                      isDefaultTemplate ||
                      !canModifyGroup
                    }
                    datatip={(() => {
                      if (isDefaultTemplate) {
                        return localstrings(t)
                          .tooltipEditTitleDefaultTemplateError;
                      } else if (attributeGroupsResponse.is_system) {
                        return localstrings(t)
                          .tooltipEditTitleSystemDefaultError;
                      } else if (!canModifyGroup) {
                        return localstrings(t).tooltipEditTitlePermissionError;
                      } else return "";
                    })()}
                    ref={titleRef}
                  />
                  <input
                    name="type"
                    ref={editTitleMethodsOfUseForm.register}
                    value="name"
                    type="hidden"
                  />
                </form>
                <form
                  id="edit-displayname-form"
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                  noValidate
                  onSubmit={editDisplayNameMethodsOfUseForm.handleSubmit(
                    handleTitleEditConfirm
                  )}
                >
                  <SoftHeaderMedium>{t("Display name: ")}</SoftHeaderMedium>
                  <EditableTitle
                    title={
                      attributeGroupsResponse.display_name
                        ? t([attributeGroupsResponse.display_name])
                        : "--"
                    }
                    formId="edit-displayname-form"
                    name="title"
                    methodsOfUseForm={editDisplayNameMethodsOfUseForm}
                    disabled={!hasPermission("modify_groups")}
                    datatip={
                      !hasPermission("modify_groups")
                        ? localstrings(t).tooltipEditTitlePermissionError
                        : ""
                    }
                    ref={displayNameRef}
                    fontSize="regular"
                    fontWeight="regular"
                  />
                  <input
                    name="type"
                    ref={editDisplayNameMethodsOfUseForm.register}
                    value="display_name"
                    type="hidden"
                  />
                </form>
              </>
            )}
            <SmallText style={{ marginBottom: "15px" }}>
              {t("This group consists of")}
            </SmallText>
          </HeadingSection>
          <div style={{ alignSelf: "flex-end" }}>
            {attributeGroupsResponse.is_system ? (
              <Flex
                style={{
                  justifyContent: "end",
                  alignItems: "center",
                  flexWrap: "nowrap",
                  margin: "0 0 15px",
                }}
              >
                <SystemIconWrapper
                  data-for={`system${template_id}`}
                  data-tip={t("System Default")}
                >
                  <SystemDefaultIcon width={22} height={22} />
                </SystemIconWrapper>
                <SoftHeader2>{t("System Default")}</SoftHeader2>
              </Flex>
            ) : canDeleteGroup ? (
              <ButtonWithConfirmDialog
                Button={(props) => (
                  <>
                    <DeleteButtonWithText
                      {...props}
                      datafor="remove-group-button"
                      datatip={deleteGroupDataTip()}
                    />
                    <ReactTooltip id="remove-group-button" />
                  </>
                )}
                buttonText={t("Remove Group")}
                disabled={
                  attributeGroupsResponse.is_system ||
                  isDefaultTemplate ||
                  template?.is_frozen ||
                  isTemplateInUse
                }
                testid={"delete-entire-group"}
                handleConfirm={handleGroupDelete}
                confirmMessage={t(
                  "Are you sure you want to remove this group?"
                )}
              />
            ) : null}
          </div>
        </TableHeaderSection>
        <ContentWrapper>
          <div style={{ marginBottom: "24px" }}>
            <Table
              columns={tableColumns}
              isLoading={isLoadingAttributes}
              error={attributesGroupsError}
              data={tableData}
              rowHover={rowHover}
              showReorderControls={isInTemplateSection}
              handleTableReorder={handleAttributesReorder}
              reorderConfirmationMessage={
                template?.number_of_products && template?.number_of_products > 0
                  ? t(
                      `{{numberOfProducts}} {{product}} will get affected by this change, Are you sure you want to confirm changes?`,
                      {
                        numberOfProducts: template.number_of_products,
                        product:
                          template.number_of_products === 1
                            ? "product"
                            : "products",
                      }
                    )
                  : undefined
              }
            />
          </div>
          <>
            {canModifyGroup && (
              <PrimaryButtonWithPlusIcon
                disabled={
                  attributeGroupsResponse.is_system || isDefaultTemplate
                }
                onClick={() => setShowAddAttributeToGroupForm(true)}
                datafor="add-attribute-button"
                datatip={(() => {
                  if (isDefaultTemplate) {
                    return localstrings(t)
                      .tooltipAddAttributeDefaultTemplateError;
                  } else if (attributeGroupsResponse.is_system) {
                    return localstrings(t)
                      .tooltipAddAttributeSystemDefaultError;
                  } else return "";
                })()}
                style={{ fontSize: "15px" }}
              >
                {t("Add attribute")}
              </PrimaryButtonWithPlusIcon>
            )}
            <ReactTooltip id="add-attribute-button" />
          </>
          {hasMultipleLanguages && !isInTemplateSection ? (
            <div style={{ marginTop: "32px" }}>
              <GroupTranslations
                group={attributeGroupsResponse}
                mutate={mutateGroup}
              />
            </div>
          ) : (
            <></>
          )}
          <SlideOut
            show={showAddAttributeToGroupForm}
            closeFlyout={() => setShowAddAttributeToGroupForm(false)}
          >
            <AddAttributeToExistingGroup
              group={attributeGroupsResponse}
              isTemplateInUse={isTemplateInUse}
              onSuccess={() => {
                mutateGroup();
                setShowAddAttributeToGroupForm(false);
              }}
            />
          </SlideOut>
        </ContentWrapper>
      </PageWrapper>
    );
  } else return null;
}
